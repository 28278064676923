import { ICourse } from 'api';
import { IOption, Pagination, User } from 'models';

export type Subscription = {
  course: {
    receivingMethod: ReceivingMethod;
    id: string;
    title: string;
    level: string;
    imageUrl: string;
    miniImageUrl: string;
  };
  purchase: Purchase;
};

export type Purchase = {
  id: string;
  paymentDate: number;
  startDate: number;
  paymentId: string;
  courseId: string;
  userId: string;
  subscriptionType: SubscriptionType;
  expirationDate: number;
  paidFrom?: PaidFrom;
  purchaseToken: string;
  isActive: boolean;
  isExpired: boolean;
  isExtended: boolean;
  isDeclinedByAdmin: boolean;
  isPermanent: boolean;
  createdAt: Date;
};

export enum ReceivingMethod {
  BOUGHT = 'bought',
  FROM_ADMIN = 'fromAdmin'
}

export enum SubscriptionType {
  THREE_MONTHS = 'three_months',
  SIX_MONTHS = 'six_months',
  ONE_YEAR = 'one_year',
  TWO_YEAR = 'two_year'
}

export enum PaidFrom {
  PLATRON = 'platron',
  AMO_CRM = 'amo_crm',
  GOOGLLE_PLAY = 'google_play',
  APP_STORE = 'app_store',
  ADMIN = 'admin'
}

export type GetSubscriptionsDto = {
  userId: string;
};

export type AddSubscriptionsDto = {
  userId: string;
  usersPage?: boolean;
  body: {
    courses: {
      courseId: string;
      isPermanent: boolean;
      purchaseDate?: number;
      expirationDate?: number;
    }[];
    isSendEmail?: boolean;
  };
};

export type DeleteSubscriptionsDto = {
  userId: string;
  body: {
    courseIds: string[];
  };
};

export type UpdateSubscriptionDto = {
  userId: string;
  purchaseId: string;
  body: {
    isPermanent: boolean;
    newPurchaseDate?: number;
    newExpirationDate?: number;
  };
};

export type AddSubscriptionsFormValues = {
  courses: {
    course: ICourse;
    subscriptionTerm: SubscriptionTerm;
    from?: string;
    to?: string;
  }[];
  isSendEmail: boolean;
};

export type UpdateSubscriptionFormValues = {
  subscriptionTerm: SubscriptionTerm;
  from?: string;
  to?: string;
};

export enum SubscriptionTerm {
  PERMANENT = 'permanent',
  THREE_MONTHS = 'three_months',
  SIX_MONTHS = 'six_months',
  NINE_MONTHS = 'nine_months',
  TWELVE_MONTHS = 'twelve_months',
  TWENTY_FOUR_MONTHS = 'twenty_four_months',
  ARBITRARY = 'arbitrary'
}

export const subscriptionTermOptionsMonths: IOption[] = [
  { label: '3 месяца', value: SubscriptionTerm.THREE_MONTHS },
  { label: '6 месяцев ', value: SubscriptionTerm.SIX_MONTHS },
  { label: '9 месяцев', value: SubscriptionTerm.NINE_MONTHS },
  { label: '12 месяцев', value: SubscriptionTerm.TWELVE_MONTHS },
  { label: '24 месяца', value: SubscriptionTerm.TWENTY_FOUR_MONTHS }
];

export const subscriptionTermOptions: IOption[] = [
  { label: 'Бессрочно', value: SubscriptionTerm.PERMANENT },
  ...subscriptionTermOptionsMonths,
  { label: 'Выбрать даты', value: SubscriptionTerm.ARBITRARY }
];

export interface GetPurchasesDto {
  page: number;
  itemsPerPage: number;
  search?: string;
  paidFrom?: PaidFrom;
  sortOrder?: 'asc' | 'desc' | string;
}

interface PurchaseWithPopulate extends Purchase {
  user: User;
  course: ICourse;
}

export interface GetPurchasesResponseDto {
  data: PurchaseWithPopulate[];
  pagination: Pagination;
}
